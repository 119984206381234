<template>
<section class="content">
  <div class="card card-info">
    <div class="card-header">
          <div class="box-tools card-tools toolbar">
            <router-link to="/materi/create" class="btn btn-primary btn-flat"
              ><i class="fa fa-plus"></i> Tambah Materi
            </router-link>
          </div>
    </div>
    <div class="card-body">
      <table class="table table-hover" ref="tblmapelmateri">
            <thead>
              <tr>
                <th>NAMA MAPEL</th>
                <th>JUDUL MATERI</th>
                <th>STATUS</th>
                <th>TINDAKAN</th>
              </tr>
            </thead>
            <tbody @click="handleClick"></tbody>
      </table>
    </div>
  </div>
</section>
</template>

<script>
import { authFetch, createTable } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import { VueEditor } from "vue2-editor";
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";

export default {
  created: function () {
    this.roles = this.$route.meta.roles;
    console.log('load initial data', this.$route);

    authFetch('/akademik/data_guru/mapel')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optMapel = js.data;
            })
  },
  components: { 
    VueEditor,
    vSelect, 
  },
  data: () => ({
    optMapel: [],
    roles: '',
    form: {
      content: "<h1>Tulis konten di sini..</h1>",
      mapel_id: "",
      title: "",
    },
    method: "POST",
    customToolbar: [
      [{ font: [] }],
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike"],
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" }
      ],
      [{ header: 1 }, { header: 2 }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ color: [] }, { background: [] }],
      ["link", "image", "video"],
      [{ direction: "rtl" }],
      // ["clean"]
    ],
  }),
  methods: {
    submitForm: function (ev) {
      const e = this.$refs;

      var data = Object.keys(this.form)
        .map(
          (key) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])
        )
        .join("&");
      var urlSubmit = "/akademik/mapel_materi";
      if (this.method == "PUT")
        urlSubmit = "/akademik/mapel_materi/" + this.form.id;

      Swal.fire({
        title: "Simpan?",
        showCancelButton: true,
        confirmButtonText: `Ya`,
        cancelButtonText: "Tidak",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          authFetch(urlSubmit, {
            method: this.method,
            body: data,
          })
            .then((res) => {
              return res.json();
            })
            .then((js) => {
              if (js.success) {
                Swal.fire("Proses Berhasil", ``, "success");
                this.form = {};
                this.table.api().ajax.reload();
              } else {
                Swal.fire("Proses gagal", ``, "error");
                this.table.api().ajax.reload();
              }
            });
        }
      });

      ev.preventDefault();
    },
    handleClick(e) {
      // const evt = this.$refs;
      if (e.target.closest("button")) {
        let id = e.target.dataset.id;
          if (e.target.dataset.action == "view") {
              this.$router.push("/preview-materi/" + e.target.dataset.id);
          } else if (e.target.dataset.action == "edit") {
            this.$router.push({ path: `/materi/edit/${id}`});
          } else if (e.target.dataset.action == "hapus") {
              Swal.fire({
              title: "Hapus materi?",
              icon: "question",
              denyButtonText: '<i class="fa fa-times"></i> Hapus',
              showCancelButton: true,
              showDenyButton: true,
              showConfirmButton: false,
            }).then((result) => {
              if (result.isDenied) {
                authFetch("/akademik/mapel_materi/" + id, {
                  method: "DELETE",
                  body: "id=" + id,
                })
                  .then((res) => {
                    return res.json();
                  })
                  .then((js) => {
                    this.table.api().ajax.reload();
                  });
              }
            });
          }
      }
    },
  },
  mounted() {
    const e = this.$refs;
    let self = this;
    this.table = createTable(e.tblmapelmateri, {
      title: "",
      roles: this.$route.params.roles,
      ajax: "/akademik/mapel_materi",
      frame: false,
      filter: true,
      selectedRowClass: "",
      serverSide: true,
      columns: [
        { data: "nama_mapel" },
        { data: "title" },
        { data: "status",
          render: function (data, type, row, meta) {
            return data > 0 ? '<span class="badge badge-success">active</span>' : '<span class="badge badge-secondary">not active</span>';
          }, },
        { data: "action",
          sortable: false,
          render: function (data, type, row, meta) {
              let dropdown =
            `<div class="btn-group">
            <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Klik
            </button>
            <div class="dropdown-menu dropdown-menu-right">
            <button type="button" class="btn btn-sm btn-info dropdown-item" data-action="view" data-id="` +
                    row.id +
                    `"> Preview </button>
            <button type="button" class="btn btn-sm btn-warning dropdown-item" data-action="edit" data-id="` +
                    row.id +
                    `"> Edit </button>
            <button type="button" class="btn btn-sm btn-danger dropdown-item" data-action="hapus" data-id="` +
                    row.id +
                    `">Hapus</button>
            </div>
            </div>`;

            return dropdown;
          }, },
      ],
      filterBy: [0, 1],
      rowCallback: function (row, data) {},
    });
  },
};
</script>